// const url = 'http://127.0.0.1:8000';
const url = 'https://digit-kiosk.com';
// const url = 'https://digit-kiosk-api.2-stb.com';
// const url = 'https://dev-digit-kiosk-api.2-stb.com';

// const imgUrl = 'http://127.0.0.1:8000';
const imgUrl = 'https://digit-kiosk.com/public';
// const imgUrl = 'https://digit-kiosk-api.2-stb.com/public';
// const imgUrl = 'https://dev-digit-kiosk-api.2-stb.com/public';

// const subUrl = 'http://127.0.0.1:8000';
// const subUrl = 'https://digit-kiosk.com';
const subUrl = 'https://digit-kiosk-api.2-stb.com';
// const subUrl = 'https://dev-digit-kiosk-api.2-stb.com';

export { url, subUrl, imgUrl }